.training-list {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 40px;

  .training-list__month-box {
    display: block;
    position: relative;
    margin-bottom: 30px;
  }

  .training-list__month-heading {
    z-index: 5;
    display: block;
    position: sticky;
    top: 0;
    padding: 6px 20px 7px 5px;
    background-color: $aug_gold;
    background-image: linear-gradient(32deg, $aug_gold, #b67700);
    color: $aug_dark;
    font-size: 1.3em;
    line-height: 1;
    opacity: 0.98;
  }

  .training-list__training-unit {
    @include aug_bg_dark();

    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 10px 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    border-left-width: 4px;
    border-left-style: solid;

    &:last-child {
      margin: 0;
    }

    &:hover {
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
    }

    &:focus {
      outline: none !important;
    }

    .training-list__attendance-column--loading {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.7);

      .loader {
        width: 50px;
        height: 50px;
        border-width: 4px;
      }
    }

    &.saving {
      .training-list__attendance-column {
        .training-list__attendance-column--loading {
          display: flex;
        }
      }
    }
  }

  .training-list__training-unit.closed {
    &:after {
      @include background_image_contain();

      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 25px);
      right: 25px;
      bottom: 0;
      width: 50px;
      height: 50px;
      background-image: url('../img/icon-lock.svg');
      opacity: 0.9;
    }

    .training-list__attendance-column {
      opacity: 0.4;
    }
  }

  .training-list__training-unit.within-deadline {
    .training-list__countdown-box {
      width: 58px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      right: 0;
      font-size: 10px;
      padding: 0;
      // can't decide...
      position: relative;
      width: 100%;
      top: initial;
      right: initial;
      padding: 2px 10px 3px;
      justify-content: space-between;
      align-items: center;
      opacity: 0.9;

      .training-list__countdown-box--text {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
        font-weight: 300;
        margin-right: 20px;

        &:before {
          @include background_image_contain();

          content: '';
          display: inline-block;
          width: 24px;
          height: 18px;
          margin-right: 10px;
          background-image: url('../img/icon-bell.svg');
        }
      }

      .training-list__countdown-box--counter {
        font-weight: 400;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }

    &.player-not-registered {
      border: 2px solid $aug_red-bright;

      .training-list__countdown-box {
        position: relative;
        width: 100%;
        top: initial;
        right: initial;
        padding: 2px 10px 0;
        justify-content: space-between;
        align-items: center;
        background: $aug_red-bright;
        opacity: 1;

        .training-list__countdown-box--text {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          font-weight: 400;
        }

        .training-list__countdown-box--counter {
          font-weight: 500;
          letter-spacing: 1px;
          font-size: 16px;
        }
      }
    }
  }


  .training-list__content-column {
    display: block;
    flex: 1;
    padding: 10px 12px;

    .training-list__title-row {
      display: flex;
      justify-content: space-between;

      .training-list__title {
        font-size: 0.8em;
        font-weight: 200;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        color: $aug_gold;
        margin: 3px 0;
      }
    }

    .training-list__date {
      font-size: 20px;
      font-weight: 600;

      &:before {
        @include background_image_contain();

        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 12px;
        background-image: url('../img/icon-date.svg');
      }
    }

    .training-list__time {
      display: flex;
      align-items: center;
      line-height: 1;
      margin: 5px 0 10px;
      font-weight: 200;
      font-size: 19px;

      &:before {
        @include background_image_contain();

        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 12px;
        background-image: url('../img/icon-clock.svg');
      }
    }

    .training-list__location {
      display: flex;
      align-items: center;
      line-height: 1;
      margin: 0 0 15px;
      font-size: 14px;
      font-weight: 200;

      &:before {
        @include background_image_contain();

        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 12px;
        background-image: url('../img/icon-location.svg');
      }
    }

    .training-list__group {
      @include aug_role_tag();

      display: inline-flex;
      align-items: center;
      padding: 3px 5px;
      color: grey;
      border-color: grey;
    }
  }

  .training-list__announcement {
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 300;
    background: black;
    width: 100%;

    &:before {
      @include background_image_contain();

      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 12px;
      background-image: url('../img/icon-important-info.svg');
    }
  }

  .training-list__attendance-column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100px;
    font-size: 0.8em;
    text-transform: uppercase;
    background-color: $aug_gold;

    &.attending {
      background-color: $aug_green-bright;
      opacity: 0.9;
    }

    &.absent {
      background-color: $aug_red-bright;
      opacity: 0.9;
    }

    .training-list__attendance-column--icon {
      @include background_image_contain();

      display: block;
      width: 80px;
      height: 40px;
      background-image: url('../img/icon-questions.svg');

      &.attending {
        background-image: url('../img/icon-check.svg');
      }

      &.absent {
        background-image: url('../img/icon-cross.svg');
      }
    }
  }

  .training-list__attendance-popup {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin: 10px;
    padding: 10px;
    border-top: 1px solid white;
    background: rgba(255, 255, 255, 0.04);

    &.active {
      display: flex;
    }

    .training-list__attendance-popup--title {
      width: 100%;
      //padding-left: 10px;
      margin: 5px 10px 10px;
      font-size: 0.8em;
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      //color: $aug_gold;
    }

    .training-list__attendance-popup--item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 40%;
      height: 80px;
      margin: 5px;
      border: 2px solid white;

      .training-list__attendance-popup--item-icon {
        @include background_image_contain();

        display: block;
        width: 40px;
        height: 40px;

        &.yes {
          background-image: url('../img/icon-check.svg');
        }

        &.no {
          background-image: url('../img/icon-cross.svg');
        }
      }
    }

    input {
      position: absolute;
      transform: translate(-9999px, 0);
      z-index: -9999;
    }

    input:checked + label {
      //border-color: $aug_gold;

      &.yes {
        background-color: $aug_green-bright;
      }

      &.no {
        background-color: $aug_red-bright;
      }
    }

    .training-list__save-selection {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      width: 100%;
      height: 60px;
      margin: 20px 5px 5px;
      letter-spacing: 0.2rem;
      font-weight: 500;
      background-color: $aug_gold;
      color: black;
      background-color: transparent;
      color: $aug_gold;
      border: 1px solid $aug_gold;
    }

    .training-list__delete-selection {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      width: 20%;
      height: 60px;
      margin: 5px;
      border: 1px solid white;
    }
  }

  &.past-trainings-list {
    .training-list__title-row {
      flex-wrap: wrap;
    }

    .training-list__time,
    .training-list__location {
      display: none;
    }
  }
}

.training-list.training-registration-list {
  width: calc(100% + 30px);
  margin-left: -15px;
}

.training-list.training-shortlist {
  width: 100%;
  margin: 0;

  &.coaches-upcoming-trainings,
  .coaches-past-trainings {
    margin-bottom: 40px;
  }

  .training-shortlist__loading-dummies {
    display: block;
    position: relative;
  }

  .training-list__training-unit {
    margin-bottom: 5px;
    cursor: default;

    &.closed {
      &:after {
        top: 18px;
        right: 5px;
        bottom: 0;
        width: 30px;
        height: 30px;
        opacity: 0.9;
      }
    }

    &.loading {
      color: grey;
      opacity: 0.7;

      .training-list__attendance-column {
        background-color: $aug_dark;
      }
    }
  }

  .training-list__content-column {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: calc(100% - 40px);

    .training-list__title-row {
      //display: none;
      width: 100%;
      justify-content: flex-start;
      border-bottom: 1px solid #676767;

      .training-list__date {
        font-size: 15px;
        margin-right: 5px;
        min-width: 80px;

        &:before {
          display: none;
        }

        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }
      }

      .training-list__title {
        font-size: 14px;
        margin: 0 15px 0 0;

        @include media-breakpoint-up(lg) {
          font-size: 18px;
        }
      }
    }

    .training-list__time {
      font-size: 14px;
      margin: 5px 15px 0 0;

      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }
    }

    .training-list__location {
      font-size: 14px;
      margin: 5px 0 0;

      &:before {
        margin-right: 5px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }
    }

    .training-list__groups {
      display: none;
    }
  }

  .training-list__attendance-column {
    width: 40px;
    opacity: 0.4;

    .training-list__attendance-column--icon {
      width: 28px;
      height: 28px;
    }
  }

  .training-list__training-unit.within-deadline.player-not-registered .training-list__attendance-column {
    opacity: 1;
  }
}

.training-list.training-shortlist.coaches-upcoming-trainings {
  .training-list__training-unit {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      @include background_image_contain();

      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      top: 12px;
      right: 12px;
      width: 14px;
      height: 16px;
      opacity: 0.9;
    }

    &.closed {
      &:after {
        background-image: url('../img/icon-lock.svg');
      }
    }

    &.open {
      &:after {
        background-image: url('../img/icon-clipboard-check.svg');
      }
    }

    &.pending {
      &:after {
        background-image: url('../img/icon-edit.svg');
      }
    }

    &.canceled {
      &:after {
        background-image: url('../img/icon-cross.svg');
      }
    }

    &.completed {
      &:after {
        background-image: url('../img/icon-check.svg');
      }
    }

    .training-list__content-column {
      max-width: none;
    }
  }

  .training-list__title-row {
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .training-list__bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0 5px;

    .training-list__details-toggle--wrapper {
      display: flex;
      align-items: center;
    }

    .training-list__details-toggle {
      align-self: center;
      margin-right: 10px;
      font-weight: 200;
      line-height: 1;
      color: $aug_gold;
      font-size: 14px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;

      &:after {
        @include background_image_contain();

        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url('../img/icon-chevron-gold.svg');
        transform: rotate(90deg);
        margin-left: 10px;
        transition: transform ease-out 0.4s;
      }

      &:hover {
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
      }

      &:focus {
        outline: none !important;
      }
    }

    .training-list__details-toggle--announcement {
      @include background_image_contain();

      display: block;
      margin: 0 10px;
      width: 14px;
      height: 14px;
      background-image: url('../img/icon-comment-bubble.svg');
    }

    .training-list__details-toggle--notes {
      @include background_image_contain();

      display: block;
      margin: 0 10px;
      width: 14px;
      height: 14px;
      background-image: url('../img/icon-clipboard.svg');
    }


    .training-list__edit-training-toggle {
      align-self: flex-start;
      font-weight: 200;
      line-height: 1;
      color: $aug_gold;
      font-size: 14px;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;

      &:after {
        @include background_image_contain();

        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background-image: url('../img/icon-gear.svg');
        transform: rotate(90deg);
        margin-left: 10px;
        transition: transform ease-out 0.4s;
      }

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none !important;
      }
    }

    .training-list__groups {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 100%;
    }
  }

  .training-list__attendance-info {
    width: 100%;
    display: flex;
    //border: 1px solid $aug_gold;
    margin-top: 10px;

    .attendance-info__left-column {
      flex: 1;
    }

    .attendance-info__right-column {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin: 0;

      @include media-breakpoint-up(md) {
        margin: 0 10px;
      }
    }

    .attendance-infos {
      display: flex;

      .attendance-info {
        margin-left: 5px;
        padding: 5px;
        opacity: 0.8;
      }
    }

    .attendance-info {
      @include aug_bg_dark();

      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 10px 0;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: 500;

      .attending-players-count {
        font-weight: 600;
        font-size: 20px;
        line-height: 1;
        margin-top: 5px;
      }

      &.attending {
        font-size: 12px;

        .attending-players-count {
          color: $aug_green-bright;
          font-size: 23px;
        }
      }

      &.absent .attending-players-count {
        color: $aug_red-bright;
      }

      .attendance-info__gender-overview {
        display: flex;
        margin: 10px 0;
        gap: 10px;

        .gender {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 14px;
        }
      }
    }

    .training-list__groups {
      flex: 1;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      margin: 0 0 10px;
    }
  }

  .training-list__attendance-details-popup {
    display: none;
    width: 100%;
    margin-top: 10px;
    border-top: 1px solid #676767;
    padding: 10px;

    h3 {
      font-size: 14px;
    }

    .training-list__attendance-details-popup--gender-overview {
      display: flex;
      width: fit-content;
      margin: 10px auto 25px;
      padding: 5px 20px;
      border: 1px solid #676767;
      background-color: rgba(255, 255, 255, 0.1);

      @include media-breakpoint-up(lg) {
        margin: 10px 0 25px;
      }
    }

    .training-list__attendance-details-popup--player {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .player__name {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 600;

        &:before {
          @include background_image_contain();

          content: '';
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
      }

      &.m .player__name:before {
        background-image: url('../img/icon-gender-m.svg');
        width: 16px;
      }

      &.f .player__name:before {
        background-image: url('../img/icon-gender-f.svg');
      }

      &.x .player__name:before {
        background-image: url('../img/icon-gender-x.svg');
      }


      .player__contact-info {
        display: flex;
        flex-direction: row;
        width: fit-content;
        border-left: 1px solid grey;
        font-size: 12px;
        margin-left: 20px;

        @include media-breakpoint-up(lg) {
          display: flex;
          flex-direction: row;
          margin-left: 40px;
          font-size: 16px;
        }

        a,
        a:visited,
        a:hover {
          color: white;
        }
      }

      .player__phone {
        display: flex;
        align-items: center;
        padding-left: 20px;
        opacity: 0.9;

        &:before {
          @include background_image_contain();

          content: '';
          display: block;
          background-image: url('../img/icon-phone.svg');
          width: 20px;
          height: 20px;
        }

        @include media-breakpoint-up(lg) {
          &:before {
            width: 20px;
            height: 16px;
          }
        }
      }

      .player__mail {
        display: none;
        align-items: center;
        opacity: 0.9;
        margin-top: 5px;
        padding-left: 20px;

        &:before {
          @include background_image_contain();

          content: '';
          display: block;
          width: 16px;
          height: 12px;
          margin-right: 12px;
          background-image: url('../img/icon-mail.svg');
        }

        @include media-breakpoint-up(lg) {
          display: flex;
          margin-top: 0;
          margin-left: 20px;
          border-left: 1px solid #676767;

          &:before {
            width: 20px;
            height: 16px;
          }
        }
      }
    }
  }

  .training-list__button-edit-training {
    display: flex;
    width: 100%;
    margin: 10px auto;

    @include media-breakpoint-up(lg) {
      width: 44%;
    }
  }

  .training-list__edit-training-popup--wrapper {
    @include aug_bg_dark();

    z-index: 20;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 80px;
    right: 0;
    bottom: 76px;
    left: 0;
    box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.22);
    padding: 10px;

    @include media-breakpoint-up(xl) {
      left: 300px;
      bottom: 60px;
    }

    .training-list__edit-training-popup {
      display: block;
      position: absolute;
      width: min(90%, 400px);
      height: 100%;
      max-height: 94%;
      overflow: auto;
      padding: 10px;
      background-color: $aug_dark;

      .training-list__edit-training-popup--heading {
        text-transform: uppercase;
        font-weight: 300;
        font-size: 14px;
        margin: 20px 0 10px;
      }

      .training-list__edit-training-popup--content {
        display: block;
        position: relative;
        padding: 10px;

        @include media-breakpoint-up(md) {
          padding: 20px;
        }

        @include media-breakpoint-up(lg) {
          padding: 40px;
        }
      }
    }

    .training-list__attendance-details-popup--player {
      display: flex;
      align-items: center;
      border: 2px solid black;
      border-radius: 4px;
      margin-bottom: 5px;

      .player__attendance {
        flex: 1;
        display: flex;
        align-items: center;
      }

      .player__name {
        font-size: 18px;
        letter-spacing: 1px;
        margin-right: 20px;
        padding: 10px 0;
      }

      .player__training-state--checkbox {
        @include background_image_contain();

        display: inline-block;
        height: 20px;
        width: 20px;
        border: 1px solid grey;
      }

      .player__training-state {
        display: flex;
        align-items: center;
        align-self: stretch;
        padding: 0 5px;
        margin-right: 20px;
        opacity: 0.4;

        &.player__training-confirmation {
          flex: 1;
          padding: 0 10px 0 0;
          margin-right: 0;
          opacity: 1;

          .player__training-confirmation--gradient {
            flex: 1;
            display: block;
            height: 100%;
            border-right: 10px solid $aug_dark;
          }

          .player__training-state--checkbox {
            width: 25px;
            height: 25px;
          }
        }

        &.attending {
          background-color: $aug_green-dark;

          .player__training-state--checkbox {
            background-image: url('../img/icon-check.svg');
            border-color: $aug_green-dark;
          }

          .player__training-confirmation--gradient {
            background-image: linear-gradient(90deg, $aug_dark, $aug_green-dark);
            border-right: 10px solid $aug_green-dark;
          }
        }

        &.absent {
          background-color: $aug_red_dark;

          .player__training-state--checkbox {
            background-image: url('../img/icon-cross.svg');
            border-color: $aug_red_dark;
          }

          .player__training-confirmation--gradient {
            background-image: linear-gradient(90deg, $aug_dark, $aug_red_dark);
            border-right: 10px solid $aug_red_dark;
          }
        }
      }

      .player__training-penalty {
        .player__training-penalty--icon {
          @include background_image_contain();

          margin: 0 15px;
          width: 20px;
          height: 20px;
          background-image: url('../img/circle-check.svg');
        }
      }

      &.penalty {
        //border: 2px solid $aug_red-bright;

        .player__training-penalty {
          .player__training-penalty--icon {
            background-image: url('../img/circle-exclamation.svg');
          }
        }
      }

      &.excluded-from-penalty {
        .player__training-penalty {
          .player__training-penalty--icon {
            background-image: url('../img/shield-heart.svg');
          }
        }
      }

      &.required {
        border-color: $aug_red-bright;
      }
    }

    .aug-button {
      width: 100%;
    }

    select {
      @include aug_bg_dark();

      padding: 10px;
      color: white;
    }

    textarea {
      @include aug_bg_dark();

      padding: 10px;
      width: 100%;
      color: white;
    }
  }

  .training-list__training-unit.active {
    .training-list__attendance-details-popup {
      display: block;
    }

    .training-list__attending-players-list {
      display: flex;
      justify-content: space-evenly;
    }

    .training-list__details-toggle {
      &:after {
        transform: rotate(270deg);
      }
    }
  }

  // Gender icons
  .gender {
    display: flex;
    align-items: center;
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid #676767;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
      border: 0;
    }

    &:before {
      @include background_image_contain();

      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

    &.m:before {
      background-image: url('../img/icon-gender-m.svg');

      height: 16px;
    }

    &.f:before {
      background-image: url('../img/icon-gender-f.svg');
    }

    &.x:before {
      background-image: url('../img/icon-gender-x.svg');
    }
  }

  .close-popup {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 0 0 auto;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 200;
    color: $aug_gold;

    &:after {
      @include background_image_contain();

      content: '';
      display: block;
      width: 42px;
      height: 42px;
      margin-left: 10px;
      background-image: url('../img/icon-cross_gold.svg');
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.training-list__attendance-list-type-toggle {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;

  .training-list__attendance-list {
    @include aug_bg_dark();

    padding: 4px;
    font-size: 12px;
    text-transform: uppercase;
    color: $aug_gold;
    border: 1px solid $aug_gold;
    border-radius: 2px;
    min-width: 100px;
    text-align: center;
    font-weight: 400;

    &.active {
      background-color: $aug_gold;
      color: black;
    }
  }
}

.training-list.coaches-confirmation-list {
  margin: 0 -5px 25px;
  width: calc(100% + 10px);
  border: 5px solid $aug_gold;

  .complete-training-confirmation-popup__wrapper {
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px;
  }

  .complete-training-confirmation-popup {
    background: $aug_dark;
    padding: 20px;
  }

  .complete-training-confirmation-popup__title {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .complete-training-confirmation-popup__training-name {
    font-size: 18px;
    font-weight: 600;
  }

  .complete-training-confirmation-popup__details {
    border: 1px solid $aug_gold;
    margin: 15px 0;
    padding: 15px;
  }

  .complete-training-confirmation-popup__attendance-counts {
    font-size: 19px;
    color: $aug_gold;
  }

  .complete-training-confirmation-popup__penalty-lists {
    display: flex;
    gap: 15px;
  }

  .complete-training-confirmation-popup__penalty-list {
    flex: 1;
    margin-top: 15px;
    font-size: 14px;
    line-height: 1.4;

    &--heading {
      text-decoration: underline;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
}

.training-list.training-shortlist.coaches-upcoming-trainings.coaches-past-trainings {
  .training-list__training-unit {
    margin-bottom: 10px;

    &:after {
      content: none;
    }
  }

  .training-list__content-column {
    justify-content: space-between;
    padding: 5px 10px;
  }

  .training-list__title,
  .training-list__time,
  .training-list__location,
  .attendance-info__right-column {
    display: none;
  }

  .training-list__title-row {
    width: auto;
    margin: 0;
    padding: 0;
    border: none;

    .training-list__date {
      font-size: 18px;
      line-height: 1;
    }
  }

  .training-list__attendance-info {
    width: auto;
    margin: 0;

    .attendance-info {
      flex-direction: row-reverse;
      align-items: center;
      margin: 0;
      background: transparent;
      backdrop-filter: none;

      .attendance-info__title {
        display: none;
      }

      .attending-players-count {
        width: 36px;
        text-align: right;
        font-size: 24px;
        margin: 0 0 0 15px;
      }

      .attendance-info__gender-overview {
        margin: 0;

        .gender {
          width: 50px;
        }
      }
    }
  }

  .training-list__bottom-row {
    margin-top: 8px;

    .training-list__details-toggle,
    .training-list__edit-training-toggle {
      font-size: 12px;
    }
  }
}

.training-list__training-unit-popup {
  @include aug_bg_dark();

  margin: -5px 0px 0px;
  padding: 20px 20px 20px 15px;
  font-size: 16px;
  border-left-width: 4px;
  border-left-style: solid;

  .training-unit-popup__players {
    display:flex;
    flex-wrap: wrap;
    gap: 0 10px;
    //display: grid;
    //gap: 10px;
    //grid-template-columns: 1fr 1fr 1fr;
  }

  .training-unit-popup__jersey-nr {
    font-weight: 800;
    color: grey;
  }

  .training-unit-popup__count {
    color: $aug_gold;
    margin: 0 0 5px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.70;
    letter-spacing: 1px;


    span {
      color: $aug_gold;
      font-size: 14px;
      font-weight: 900;
    }
  }
}