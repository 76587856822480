// Base
@import '~normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&display=swap');
@import 'mixins';
@import 'general';

// Misc.
@import "training-list";
@import "login-screen";
@import "aug-select";
@import "./season-stats/players-stats";
@import "./season-stats/season-stats";
@import "player-training-stats-info";
@import "app-info-popup";

// Club
@import "club/club-stats-box";
@import "club/club-dates-calendar";

// Club Members
@import 'members';

// Personal data
@import "personal-data/personal-data";

.myaug-popup__wrapper {
  @include aug_bg_dark();

  z-index: 800;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: flex-start;
  top: 60px;
  right: 0;
  bottom: 76px;
  left: 0;
  box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.22);
  background-color: rgba(0, 0, 0, 0.74);
  padding: 15px 10px;

  @include media-breakpoint-up(xl) {
    left: 300px;
    bottom: 60px;
  }

  .myaug-popup {
    display: block;
    position: absolute;
    width: min(90%, 400px);
    height: auto;
    max-height: 94%;
    overflow: auto;
    padding: 10px;
    background-color: $aug_dark;
  }

  .close-popup {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 0 0 auto;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 200;
    color: $aug_gold;

    &:after {
      @include background_image_contain();

      content: '';
      display: block;
      width: 42px;
      height: 42px;
      margin-left: 10px;
      background-image: url('../img/icon-cross_gold.svg');
    }

    &:hover {
      cursor: pointer;
    }
  }
}


.header-bar-menu {
  a,
  a:visited,
  a:hover {
    text-decoration: none;
    color: white;
  }
}

.Toastify__toast-container {
  max-width: 80vw;
  bottom: 80px !important;

  .Toastify__toast {
    min-height: auto;
    font-size: 14px;
    margin-bottom: 15px;
  }
}

.Toastify__toast-theme--dark {
  @include aug_bg_dark();

  --toastify-icon-color-success: #337649;
  --toastify-color-progress-success: #337649;
  --toastify-color-dark: linear-gradient(40deg, rgba(0, 0, 0, 0.36) 40%, rgba(0, 0, 0, 0.42) 98%);
}


.header-bar {
  z-index: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: $aug_dark;

  @include media-breakpoint-up(md) {
    height: 60px;
  }

  @include media-breakpoint-up(lg) {
    @include aug_bg_dark();

    background-color: $aug_dark;
  }
}

.header-bar__title-logo {
  @include background_image_contain();

  width: 170px;
  margin-left: 2vw;
  background-image: url('../img/mygaertner-logo.svg');
  height: 48px;

  @include media-breakpoint-up(md) {
    height: 60px;
  }

  h1 {
    display: none;
    visibility: hidden;
    margin: 0;
  }
}


.hamburger-react {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  margin: 6px;

  @include media-breakpoint-up(md) {
    margin: 16px;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.header-bar-menu-wrapper {
  @include aug_bg_dark();

  z-index: 990;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  position: fixed;
  top: 60px;
  right: 0;
  width: 100%;
  transform: translate(100%, 0);
  transition: all ease-in-out 0.2s;
  height: calc(100vh - 136px);

  &.active {
    transform: none;
  }

  a, a:active {
    text-decoration: none;
  }

  a:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  @include media-breakpoint-up(md) {
    top: 60px;
  }

  @include media-breakpoint-up(lg) {
    overflow: auto;
  }

  @include media-breakpoint-up(xl) {
    transform: none;
    overflow: auto;
    width: 300px;
    left: 0;
    right: auto;
    bottom: 0;
    height: calc(100vh - 60px);
  }

  ul.header-bar-menu {
    min-width: 280px;
    max-width: 298px;
    width: fit-content;
    margin: 0 0 0 auto;
    padding: 15px;
    list-style: none;
    //height: 100%;
    min-height: calc(100vh - 136px);
    background-image: linear-gradient(40deg, rgba(18, 21, 28, 0.94) 22%, rgba(24, 27, 34, 0.970) 78%);

    @include media-breakpoint-up(xl) {
      min-height: calc(100vh - 60px);
    }

    li.header-bar-menu__item {
      margin: 0;
    }

    .item-link {
      display: flex;
      align-items: center;
      padding: 20px;
      border-top: 1px solid lightgrey;

      &.main-link {
        font-weight: 600;
        letter-spacing: 2px;

        &:last-child {
          border-bottom: 1px solid lightgrey;
        }
      }

      &.active {
        color: $aug_gold;
        //border-left: 3px solid $aug_gold;
      }
    }

    @include media-breakpoint-up(xl) {
      min-width: auto;
      width: 300px;
    }
  }

  ul.header-bar-submenu {
    margin: 0;
    padding: 0;
    list-style: none;

    .item-link {
      padding: 20px 20px 20px 30px;
      border-top: none;
      border-top: 1px solid #3c3c3c;
    }
  }

  .item-link.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
    color: grey;
  }

  .item-link.item-link__coaches {
    &:after {
      @include background_image_contain();

      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url('../img/icon-whistle.svg');
      margin-left: auto;
    }
  }

  .item-link.item-link__board-members {
    &:after {
      @include background_image_contain();

      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url('../img/icon-board.svg');
      margin-left: auto;
    }
  }

  .item-link.log-out {
    &:after {
      @include background_image_contain();

      content: '';
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
      margin: 0 0 0 20px;
      background-image: url('../img/log-out.svg');
    }

    &:hover {
      background: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
  }

  button[type=button] {
    //temporary google logout button styling
    margin: 30px auto;
    display: block;
    padding: 14px 30px;
  }

  a.refresh-app-link {
    display: flex;
    align-items: center;
    padding: 15px 20px 0;
    font-size: 12px;
    width: fit-content;
    margin-left: auto;
    color: grey;

    &:hover {
      background: transparent;
    }

    &:active,
    &:visited {
      color: grey;
    }

    &:before {
      @include background_image_contain();

      content: '';
      display: block;
      width: 13px;
      height: 13px;
      margin-right: 8px;
      background-image: url('../img/icon-refresh.svg');
    }
  }
}

.profil-bar {
  @include gold-gradient-bg();

  display: flex;
  position: relative;
  padding: 0;
  height: 96px;

  @include media-breakpoint-up(lg) {
    max-width: 1000px;
    margin: 0 auto 0 40px;
  }

  .profil-bar__info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: $aug_dark;
    padding: 10px;
  }

  .profil-bar__name {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 26px;
    line-height: 1;
  }

  .profil-bar__roles {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .profil-bar__member-type {
    flex: 1;
    font-size: 0.8em;
    padding-left: 5px;
  }

  .profil-bar__member-fee-info {
    flex: 1;
    font-size: 0.7em;
  }
}

main {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px 15px 100px;

  @include media-breakpoint-up(lg) {
    max-width: 1000px;
    margin: 0 auto 0 40px;
  }
}

.nav-bar {
  @include aug_bg_dark();

  z-index: 500;
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 15px;
  background: $aug_dark;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: grey;
  }

  @include media-breakpoint-up(xl) {
    left: 300px;
  }

  ul.navigation {
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .navigation__link-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 10px 5px;
    padding: 20px;

    .navigation__link {
      @include background_image_contain();

      display: block;
      position: absolute;
      width: 100%;
      height: 100%;

      &--home {
        background-image: url('../img/icon-start-white.svg');

        &.active {
          background-image: url('../img/icon-start-gold.svg');
        }
      }

      &--financials {
        background-image: url('../img/icon-coins.svg');
      }

      &--calendar {
        background-image: url('../img/icon-training-shoes-white.svg');

        &.active {
          background-image: url('../img/icon-training-shoes-gold.svg');
        }
      }

      &--club {
        background-image: url('../img/icon-mono-logo-white.svg');

        &.active {
          background-image: url('../img/icon-mono-logo-gold.svg');
        }
      }
    }
  }
}

.home {
  h2 {
    @include aug_bg_dark();

    color: white;
    width: calc(100% + 30px);
    margin: 20px 0 15px -15px;
    padding: 4px 15px 2px;
    position: relative;
  }

  .home__heading-link {
    h2 {
      @include aug_bg_dark();

      color: $aug_gold;
      width: calc(100% + 30px);
      margin: 20px 0 15px -15px;
      padding: 4px 15px 2px;
      position: relative;

      &:after {
        @include background_image_contain();

        content: '';
        display: flex;
        align-items: center;
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        width: 14px;
        background-image: url('../img/icon-chevron-gold.svg');
      }
    }
  }

  ul.navigation {
    flex: 1;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
    grid-template-areas:
    "training financials"
    "info profile";
    list-style: none;
    margin: 20px;
    padding: 0;
  }

  .navigation__link-item {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid grey;
    margin: 30px;
    padding: 40px;

    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
}

.member-role-tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.member-role-tag {
  @include aug_role_tag();

  &.trainer {
    @include aug_role_tag($aug_red-super-bright, true);
  }

  &.cashier {
    @include aug_role_tag($aug_green-super-bright, true);
  }

  &.info-bar-tag {
    @include aug_role_tag($aug_dark);
  }

  &.basic {
    @include aug_role_tag(lightgrey, true);
  }

  &.honourable {
    @include aug_role_tag($aug_gold, true);
  }

  &.full {
    @include aug_role_tag($aug_green-bright, true);
  }

  &.supporting {
    @include aug_role_tag(#69a8bb, true);
  }

  &.idle {
    @include aug_role_tag(lightgrey, true);
  }
}

.info-messages-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 5px 0 0;

  @include media-breakpoint-up(md) {
    max-width: 1000px;
    margin: 0 auto 0 40px;
  }

  .info-message {
    @include aug_bg_dark();

    display: block;
    position: relative;
    width: 100%;
    margin: 15px 0 0;
    padding: 5px 5px 5px 44px;
    color: $aug_gold;
    border: 1px solid $aug_gold;
    line-height: 1.6;

    @include media-breakpoint-up(md) {
      margin: 25px 0 0;
      padding: 12px 12px 12px 60px;
    }

    p {
      margin: 0;
    }

    &:before {
      @include background_image_contain();

      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $aug_gold;
      background-image: url('../img/icon-info.svg');
      width: 20px;
      border-left: 8px solid $aug_gold;
      border-right: 8px solid $aug_gold;
    }

    &.important {
      color: white;
      border: 1px solid $aug_red-bright;
      font-weight: 500;

      &:before {
        background-color: $aug_red-bright;
        background-image: url('../img/icon-important-info.svg');
        border-color: $aug_red-bright;
      }
    }

    &.member-fee-info-message {
      color: white;
      border: 1px solid $aug_red-bright;
      font-weight: 500;
      background-image: linear-gradient(270deg, transparent 5%, rgba(127, 38, 36, 0.37) 99%);
      background-color: rgba(0, 0, 0, 0.2);

      &:before {
        background-color: $aug_red-bright;
        background-image: url('../img/icon-coins_white.svg');
        border-color: $aug_red-bright;
      }

      .member-fee-info-message__link {
        margin-left: 20px;
      }
    }
  }
}


.member-fee-info {
  position: relative;
  padding: 10px;
  color: white;
  border: 2px solid $aug_red-bright;
  font-weight: 500;
  background-image: linear-gradient(270deg, transparent 5%, rgba(127, 38, 36, 0.37) 99%);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  @include media-breakpoint-up(md) {
    padding: 20px;
  }

  &:before {
    background-color: $aug_red-bright;
    background-image: url('../img/icon-coins_white.svg');
    border-color: $aug_red-bright;
  }

  .member-fee-info__fee-name {
    width: fit-content;
    margin-bottom: 5px;
    font-size: 18px;
    letter-spacing: 1px;
    border-bottom: 1px solid $aug_gold;
    text-transform: uppercase;
  }

  .member-fee-info__member-status {
    display: flex;
    align-items: center;
    font-size: 20px;
    //letter-spacing: 2px;
    padding: 5px;
    color: $aug_gold;

    &:before {
      @include background_image_contain();

      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      background-image: url('../img/icon-cross_red.svg');
    }
  }

  .member-fee-info__content {
    margin: 10px 0;
  }

  .member-fee-info__setUserFeedbackButton {
    margin: 10px 0 5px;
    font-size: 14px;
    color: $aug_gold;
    cursor: pointer;
  }

  &.paid {
    border-color: $aug_green-bright;
    background-image: linear-gradient(270deg, transparent 5%, rgba(51, 118, 73, 0.37) 99%);

    .member-fee-info__member-status {
      &:before {
        background-image: url('../img/icon-check_green.svg');
      }
    }
  }

  &.exempt {
    border-color: $aug_gold;
    background-image: linear-gradient(270deg, transparent 5%, rgba(197, 161, 53, 0.37) 99%);

    .member-fee-info__member-status {
      &:before {
        background-image: url('../img/icon-check_gold.svg');
      }
    }
  }

  &.paid-by-user:not(.exempt):not(.paid) {
    background-image: linear-gradient(270deg, transparent 5%, rgba(157, 74, 24, 0.2) 99%);
    border-color: #9d4a18;

    .member-fee-info__member-status {
      &:before {
        background-image: url('../img/icon-question.svg');
      }
    }
  }

  .loader-wrapper {
    .loader {
      position: absolute;
    }
  }
}

.club {
  ul {
    li {
      margin-bottom: 20px;
    }
  }
}

.club__contacts {
  .club__contact-group {
    margin-bottom: 40px;
    border-left: 5px solid $aug_gold;

    .club__contact-group-name {
      display: block;
      padding: 6px 20px 7px 5px;
      background-color: $aug_gold;
      color: $aug_dark;
      font-size: 1.3em;
      line-height: 1;
    }

    .club__contact {
      @include aug_bg_dark();

      padding: 10px 12px;
      margin-bottom: 20px;
    }

    .club__contact-description {
      font-size: 0.8em;
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: $aug_gold;
      margin: 3px 0;
    }
  }
}

.past-trainings__stats {
  @include aug_bg_dark();

  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 10px 0 40px;
  padding: 10px 20px 10px 20px;
  gap: 10px;
  //border: 3px solid $aug_gold;

  svg {
    text {
      //fill: #C5A135;
      font-size: 20px;
      font-weight: 600;
    }
  }

  .past-trainings__stats-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    .past-trainings__stats-text--title {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      color: $aug_gold;
      text-align: right;
      border-bottom: 1px solid black;
    }

    .past-trainings__stats-text--counts {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-weight: 600;
      text-align: center;
    }
  }

}

.fame-info-box {
  @include aug_bg_gradient();

  background-color: $aug_gold;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  margin: 10px auto;

  .fame-info-box__heading-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 10px 0;
    color: white;
    border-bottom: 1px solid $aug_dark;
  }

  .fame-info-box__heading {
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .fame-info-box__heading-month {
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .fame-info-box__intro {
    padding: 4px 10px 12px;
    font-size: 10px;
    color: $aug_dark;
  }

  .fame-info-box__players {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 20px;
    min-height: 80px;
    padding: 5px 20px;
    color: black;
  }

  .fame-player {
    display: flex;
    align-items: center;
    flex: 0 1 calc(50% - 10px);

    .fame-player__bug-icon {
      @include background_image_contain();

      width: 20px;
      height: 20px;
      margin-right: 8px;
      background-image: url('../img/bug-gold.svg');
    }
  }

  .bg-grass {
    display: block;
    position: relative;
    height: 50px;
    background-image: url('../img/grass-gold.svg');
    opacity: 0.7;
    border-radius: 0 0 8px 8px;
  }

  &.dummy {
    background-color: $aug_dark;

    .bg-grass {
      opacity: 0.2;
    }
  }
}