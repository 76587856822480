.member-list {
  display: block;
  position: relative;
  //padding-left: 0;
  //padding-right: 0;

  .member-list__header-bar {
    z-index: 25;
    display: flex;
    flex-direction: column;
    gap: 0;
    position: sticky;
    top: 0;
    padding: 0 0 5px;
    background-color: $aug_dark;

    .member-list__search-bar {
      @include aug_bg_dark();

      margin:  0 0 5px;
      padding: 10px 20px;
      color: white;
      border: 1px solid black;
    }

    .aug-select {
      width: 100%;
      margin:  0;

      .aug-select__value-container {
        padding: 10px 20px;
      }
    }
  }

  .member-list__members-count {
    font-size: 12px;
    letter-spacing: 1px;
    margin: 2px 0 0;
    color: grey;
  }

  .member-list__letter-row {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 98px;
    margin: 20px 0 -10px -10px;
    width: 30px;
    height: 30px;
    background-color: $aug_dark;
    border-radius: 100%;
    font-size: 16px;
    font-weight: 800;
    color: #f0f0f0;
  }

  .member-list__row {
    @include aug_bg_dark();

    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
    position: relative;
    padding: 10px;
    margin-top: 20px;
    //border-bottom: 1px solid black;
  }

  &__profile-pic {
    @include background_image_contain();

    align-self: center;
    display: block;
    position: relative;
    width: 96px;
    height: 96px;
    background-image: url('../img/icon-profile-dummy.svg');
    border: 2px solid $aug_gold;
    border-radius: 100%;
    background-size: cover;
  }

  &__number {
    z-index: 0;
    display: flex;
    align-items: center;
    position: absolute;
    inset: 0 0 0 auto;
    opacity: 0.14;
    overflow: hidden;

    .member-list__number-inner {
      display: block;
      color: $aug_gold;
      width: 100%;
      margin: -14% -15px 0 0;
      font-size: 160px;
      font-weight: 900;
      line-height: 1;
      letter-spacing: -15px;
      transform: rotate(-4deg);
    }
  }

  &__member-info {
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    > * {
      width: 100%;
    }

    .name {
      line-height: 1;
      font-size: 22px;
      font-weight: 600;
    }

    .member-role-tags {
      margin: 5px 0;
    }
  }

  .member-list__status-icons {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .member-list__mail-icon {
    @include background_image_contain();

    width: 20px;
    height: 20px;
    background-image: url('../../assets/img/icon-mail_gold.svg');
  }

  .member-list__phone-icon {
    @include background_image_contain();

    width: 20px;
    height: 20px;
    background-image: url('../../assets/img/icon-phone_gold.svg');
  }

  .member-list__entry-year {
    font-size: 28px;
    letter-spacing: 0.1em;
    font-weight: 700;
    flex: 1;
    color: $aug_gold;
    opacity: 0.87;
  }

  .member-list__team-status {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 200;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 1px;
    color: $aug_gold;

    &.inactive {
      color: grey;
    }

    &.injured {
      color: $aug_red-bright;
    }
  }

  .member-list__team-status-icon--active {
    @include background_image_contain();

    width: 30px;
    height: 30px;
    background-image: url('../../assets/img/icon-training-shoes-gold.svg');
  }

  .member-list__team-status-icon--inactive {
    @include background_image_contain();

    width: 30px;
    height: 30px;
    background-image: url('../../assets/img/icon-training-shoes-white.svg');
  }

  .member-list__team-status-icon--injured {
    @include background_image_contain();

    width: 25px;
    height: 25px;
    background-image: url('../../assets/img/icon-injured.svg');
  }

  .member-list__member-status-icon {
    @include background_image_contain();

    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;

    &.honourable {
      background-image: url('../../assets/img/bug-gold.svg');
    }

    &.full {
      background-image: url('../../assets/img/bug-green.svg');
    }

    &.supporting {
      background-image: url('../../assets/img/bug-blue.svg');
    }

    &.idle {
      background-image: url('../../assets/img/bug-grey.svg');
    }
  }

  .member-list__member-status-name {
    &.honourable {
      color: $aug_gold;
    }

    &.full {
      color: $aug_green-bright;
    }

    &.supporting {
      color: #69a8bb;
    }

    &.idle {
      color: lightgrey;
    }
  }

  .member-list__club-years--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $aug_gold;
  }

  .member-list__club-years {
    @include aug_bg_dark();
    @include background_image_contain();

    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    padding: 10px;
    font-weight: 900;
    color: $aug_gold;
    font-size: 16px;
    opacity: 0.9;
    background-image: url('../../assets/img/icon-lauren-wreath_gold.svg');

    &--number {
      margin-bottom: 3px;
    }
  }

  .member-list__team-fee-status {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;

    &.paid {
      color: $aug_green-bright;
    }

    &.outstanding {
      color: $aug_red-bright
    }

    &.exempt {
      color: grey;
    }
  }

  .member-list__team-fee-status-icon {
    @include background_image_contain();

    width: 25px;
    height: 25px;

    &.paid {
      background-image: url('../img/icon-check_green.svg');
      color: $aug_green-bright;
    }

    &.outstanding {
      background-image: url('../img/icon-cross_red.svg');
      color: $aug_red-bright
    }

    &.exempt {
      background-image: url('../img/icon-dash.svg');
      color: grey;
    }
  }

  .member-list__row-expanded {
    @include aug_bg_dark();

    padding: 0 20px 25px;
  }

  .member-list__row-expanded .member-list__member-status-icon {
    position: relative;
    top: initial;
    right: initial;
    width: 30px;
    height: 30px;
  }

  .member-list__row-expanded-field {
    padding: 10px 10px 0;
    border-bottom: 1px solid $aug_dark;
  }

  .member-list__row-expanded-field--label {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px 0 10px;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .member-list__row-expanded-field--value {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0 0 20px;
    padding: 0 10px;
  }

  .member-list__training-groups {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .member-list__training-group {
      color: black;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 4px;
      padding: 3px 5px;
    }
  }

  .member-list__row-expanded--slogan {
    padding: 15px 0;
    color: grey;
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 1px;
    text-align: center;
    line-height: 1.34;
  }
}
