.club-dates-calendar {
  .club-dates-calendar__event {
    @include aug_bg_dark();

    margin-bottom: 16px;
    padding: 8px;

    .club-dates-calendar__event-date {
      display: flex;
      gap: 10px;
      padding-bottom: 4px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 900;
      color: $aug_gold;
      border-bottom: 1px solid white;
    }

    .club-dates-calendar__event-name {
      font-weight: 600;
      font-size: 18px;
      padding: 8px 4px 0;
    }
  }
}