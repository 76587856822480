
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

strong, b {
  font-weight: bold;
}

html,
body,
#my-augaertner,
.app-content {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
  line-height: 1.6;
  scroll-behavior: smooth;
}

body {
  background-color: $aug_dark;
  color: white;
}

.app-content {
  padding-top: 60px;

  @include media-breakpoint-up(xl) {
    padding-left: 300px;
  }
}

h2 {
  margin: 0 0 8px;
  font-weight: 200;
  letter-spacing: 0.1em;
  font-size: 1.6em;
  line-height: 1.8;
}

h3 {
  margin: 0 0 6px;
  font-weight: 400;
  //letter-spacing: 0.1em;
  font-size: 1.2em;
  line-height: 1.6;
  text-transform: uppercase;
  color: $aug_gold;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: $aug_gold;

  &:focus {
    outline: none !important;
  }
}

.ScrollbarsCustom-Wrapper {
  inset: 0 2px 0 0 !important;
}

.ScrollbarsCustom-Track {
  opacity: 0.7;
  width: 2px !important;
}

.ScrollbarsCustom-Thumb {
  background-image: linear-gradient(#c5a135, #c28e00 85%) !important;
}

.header-bar-menu-wrapper {
  .ScrollbarsCustom {

  }

  .ScrollbarsCustom-Wrapper {
    inset: 0 0 0 2px !important;
  }

  .ScrollbarsCustom-Thumb {
    opacity: 0.64;
    background: white !important;
  }

  .ScrollbarsCustom-Track {
    @include media-breakpoint-up(xl) {
      right: auto !important;
      left: 0;
    }
  }
}

.back-to-link {
  margin-bottom: 10px;
}

.aug-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 48px;
  margin: 25px auto;
  padding: 5px 20px;
  background-color: rgba(192, 161, 74, .8);
  background-color: $aug_gold;
  border: 1px solid #c5a135;
  border-radius: 0;
  color: $aug_dark;
  transition: all ease-out 0.2s;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 1px;

  &:hover {
    background-color: #c5a135;
    cursor: pointer;
  }

  &.wide {
    width: 100%;
  }

  &.no-margin {
    margin: 0;
  }

  &.chevron-right {
    padding: 5px 30px 5px 20px;

    &:after {
      @include background_image_contain();

      content: '';
      display: flex;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
      width: 14px;
      background-image: url('../img/icon-chevron-dark.svg');
    }
  }

  &.chevron-left {
    padding: 5px 20px 5px 30px;

    &:after {
      @include background_image_contain();

      content: '';
      display: flex;
      align-items: center;
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      width: 14px;
      background-image: url('../img/icon-chevron-dark.svg');
      transform: rotate(180deg);
    }
  }

  &.coaches {
    &:before {
      @include background_image_contain();

      content: '';
      display: flex;
      align-items: center;
      //position: absolute;
      //left: 10px;
      //top: 0;
      //bottom: 0;
      width: 20px;
      height: 20px;
      margin: 0 20px 0 -30px;
      background-image: url('../img/icon-whistle-dark.svg');
    }
  }

  &.board-members {
    &:before {
      @include background_image_contain();

      content: '';
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;
      margin: 0 20px 0 -30px;
      background-image: url('../img/icon-board-dark.svg');
    }
  }

  &.secondary {
    @include aug_bg_dark();

    color: $aug_gold;

    &.chevron-right {
      &:after {
        background-image: url('../img/icon-chevron-gold.svg');
      }
    }

    &.coaches {
      &:before {
        background-image: url('../img/icon-whistle.svg');
      }
    }

    &.board-members {
      &:before {
        background-image: url('../img/icon-board.svg');
      }
    }
  }
}


a.aug-button,
a.aug-button:visited,
a.aug-button:hover {
  text-decoration: none;
  color: $aug_dark;
}

a.aug-button.secondary,
a.aug-button.secondary:visited,
a.aug-button.secondary:hover {
  text-decoration: none;
  color: $aug_gold;
}

.ptr--ptr {
  @include aug_bg_dark();

  margin: 0;
  border-radius: 0 0 42% 42%;
  background-color: $aug_gold;

  .ptr--text {
    color: rgba(255, 255, 255, 0.7);
  }

  .ptr--icon {
    color: rgba(255, 255, 255, 0.7);
  }
}


// COACHES-CORNER
.coaches-corner__confirmation-list {
  margin-bottom: 25px;

  &--loading-info-box {
    @include aug_bg_gradient();

    display: flex;
    justify-content: center;
    padding: 6px 12px;

    .content {
      display: flex;
      width: fit-content;
      gap: 16px;
      font-size: 14px;
      font-style: italic;
      color: grey;
    }

    .loader {
      width: 20px;
      height: 20px;
      border-width: 2px;
    }
  }
}