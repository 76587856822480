$aug_gold: #c5a135;
$aug_red-bright: #8e1b1e;
$aug_red-dark: #5b191a;
$aug_red-super-bright: #e82930;
$aug_green-bright: #337649;
$aug_green-dark: #21502f;
$aug_green-super-bright: #48c26e;
$aug_white: #fff;
$aug_dark: #282c34; //rgba(40, 44, 52, 0.6)

@mixin background_image_contain() {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin media-breakpoint-up($screen-width) {
  @if ($screen-width == md) {
    @media (min-width: 768px) {
      @content;
    }
  } @else if ($screen-width == lg) {
    @media (min-width: 999px) {
      @content;
    }
  } @else if ($screen-width == xl) {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin gold-gradient-bg() {
  background-image: linear-gradient(#c5a135, #c28e00 85%);
}

@mixin aug_bg_gradient() {
  backdrop-filter: blur(2px) saturate(170%);
  -webkit-backdrop-filter: blur(2px) saturate(170%);
  background: transparent;
  background-image: linear-gradient(40deg, rgba(0, 0, 0, 0.4) 40%, rgba(255, 255, 255, 0.04) 98%);
  //background-image: radial-gradient(closest-corner at 40%, rgba(0,0,0,0.4), rgba(0,0,0,0.1));
  //background-image: radial-gradient(closest-corner at 40%, rgba(0,0,0,0.4), rgba(255,255,255,0.04));
}

@mixin aug_bg_dark() {
  backdrop-filter: blur(2px) saturate(170%);
  -webkit-backdrop-filter: blur(2px) saturate(170%);
  background: transparent;
  background-image: linear-gradient(40deg, rgba(0, 0, 0, 0.36) 40%, rgba(0, 0, 0, 0.42) 98%);
}


@mixin aug_role_tag($color: $aug_gold, $background: false) {
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1;
  padding: 3px 5px 2px;
  border-radius: 4px;
  border: 1px solid $color;
  color: $color;

  @if ($background) {
    @include aug_bg_dark();
  }
}
