.training-stats {
  .training-stats__players-list {
    display: block;
    position: relative;

    .training-stats__filter-results-count {
      display: block;
      position: sticky;
      top: 74px;
      z-index: 1;
      background: $aug_dark;
      font-size: 12px;
      padding: 0 0 10px;
      margin: 10px 0 8px;
    }

    .training-stats__players-list--player-box {
      @include aug_bg_dark();

      display: flex;
      flex-direction: column;
      position: relative;
      margin-bottom: 10px;
    }

    .player-box__content {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .player-box__attendance-percentage {
      align-self: stretch;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      font-size: 20px;
      color: white;
      letter-spacing: 2px;
      font-weight: 700;
      opacity: 0.6;
    }

    .player-box__name {
      display: flex;
      padding: 5px;
      font-size: 22px;
      font-weight: 600;
      gap: 10px;
    }

    .player-box__last-five {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 5px;
      width: 100%;

      .player-box__last-five-dot {
        display: block;
        width: 8px;
        height: 8px;
        border: 1px solid black;
        border-radius: 100%;
        background-color: $aug_red-bright;
        opacity: 0.7;

        &.true {
          background-color: $aug_green-bright;
          opacity: 0.9;
        }
      }
    }

    .player-box__attendance-total {
      padding: 5px 10px 5px;
      font-size: 18px;
      margin-left: auto;
      text-align: right;
    }


    .training-stats__players-list--player-box {
      &.goldGradient {
        .player-box__content {
          background-image: linear-gradient(270deg, transparent 5%, rgba(197, 161, 53, 0.37) 99%);
        }
      }

      &.greenGradient {
        .player-box__content {
          background-image: linear-gradient(270deg, transparent 5%, rgba(51, 118, 73, 0.37) 99%);
        }
      }

      &.orangeGradient {
        .player-box__content {
          background-image: linear-gradient(270deg, transparent 5%, rgba(157, 74, 24, 0.2) 99%);
        }
      }

      &.redGradient {
        .player-box__content {
          background-image: linear-gradient(270deg, transparent 5%, rgba(127, 38, 36, 0.37) 99%);
        }
      }
    }
  }

  .training-stats__filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: $aug_dark;

    label {
      color: $aug_gold;
      font-size: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 200;
    }

    &--sort {
      .aug-select {
        min-width: 120px;
      }
    }

    &--filter {
      flex: 1;
      margin-right: 40px;
      max-width: 280px;

      .aug-select {
        width: 100%;
      }
    }
  }
}