.login-screen {
  padding: 0;

  .login-logo {
    @include background_image_contain();

    display: block;
    width: 80%;
    height: 120px;
    margin: 40px 10%;
    background-image: url('../img/mygaertner-logo.svg');

    @include media-breakpoint-up(xl) {
      height: 140px;
    }
  }

  .login-box {
    @include aug_bg_dark();

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: fit-content;
    margin: 0 40px;
    padding: 20px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid $aug_dark;

    @include media-breakpoint-up(md) {
      max-width: 600px;
      margin: 10vh auto 20vh;
      padding: 40px 100px;
    }

    @include media-breakpoint-up(xl) {
      padding: 80px 120px;
    }

    button {
      margin: 25px;
      border-radius: 2px;

      &:hover {
        background-color: $aug_gold;
        transition: all 0.2s ease-out;
        //color: white;
          background-image: linear-gradient(#c5a135, #c28e00 85%);
      }

      @include media-breakpoint-up(md) {
        font-size: 24px;
      }
    }

    .login-info {
      padding: 0 20px;
      font-size: 14px;
      color: grey;
    }
  }

  .background-pattern {
    background-attachment: fixed;
    background-image: url('../img/bg-pattern_white.png');
    bottom: 0;
    display: block;
    left: 0;
    opacity: .014;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -2;
  }
}

.logging-in {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}