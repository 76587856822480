.aug-select {
  margin: 0 0 12px auto ;
  width: fit-content;
  outline: none;

  .aug-select__control {
    @include aug_bg_dark();

    border-color: transparent;

    &--is-focused {
      box-shadow: 0 0 4px $aug_gold;
    }
  }

  .aug-select__menu {
    @include aug_bg_dark();

    margin-top: 4px;
  }

  .aug-select__menu-list {
    @include aug_bg_dark();
  }

  .aug-select__input-container {
    color: white;
  }

  .aug-select__single-value {
    color: white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .aug-select__option {
    &--is-focused {
      background: transparent;
    }

    &--is-selected {
      background: $aug_gold;
    }
  }

  .aug-select__multi-value {
    @include aug_bg_dark();

    background-color: $aug_gold;

    .aug-select__multi-value__label {
      color: white;
      font-size: 12px;
      font-weight: 500;
    }
  }
}