.loader {
  border: 7px solid #141612;
  border-top: 7px solid #c5a135;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.6s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-wrapper-relative {
  display: flex;
  justify-content: center;
  position: relative;
  padding: 40px 0;
}

.loader-wrapper {
  z-index: 300;
  background: rgba(255, 255, 255, 0.18);
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;

  .loader {
    position: absolute;
    top: 20%;
  }
}