.player-training-stats-info {
  @include aug_bg_dark();

  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  gap: 10px;
  border-radius: 8px;

  .player-training-stats-info__stats-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 40px;
  }

  .player-training-stats-info__stats-text--title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    text-align: right;
    padding-bottom: 4px;
    border-bottom: 1px solid $aug_gold;

    .player-training-stats-info__stats-text--title-left {
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 900;
      color: white;
    }

    .player-training-stats-info__stats-text--title-right {
      font-size: 12px;
    }
  }

  .player-training-stats-info__circle {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .player-training-stats-info__counts {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 600;
    text-align: center;
    color: $aug_gold;

    .player-training-stats-info__count-attended {
      font-size: 28px;
    }

    .player-training-stats-info__count-divider {
      font-size: 28px;
      font-weight: 300;
      padding: 0 8px 0 10px;
    }

    .player-training-stats-info__count-total {
      font-size: 20px;
      font-weight: 400;
    }

    .player-training-stats-info__count-description {
      width: 100%;
      text-align: center;
      font-size: 10px;
      letter-spacing: 1px;
      font-weight: 400;
      color: $aug_gold;
    }
  }

  &.simple {
    .player-training-stats-info__stats-content {
     margin: 0;
    }
  }

  .recharts-cartesian-grid {
    line {
      stroke: #282c34;
    }
  }

  background-color: rgba(0, 0, 0, 0.7);

  &.gold {
    background-image: linear-gradient(270deg, transparent 5%, rgba(197, 161, 53, 0.37) 99%);
  }

  &.green {
    background-image: linear-gradient(270deg, rgba(20,20,20,0.2) 5%, rgba(51, 118, 73, 0.37) 99%);
  }

  &.orange {
    background-image: linear-gradient(270deg, transparent 5%, rgba(157, 74, 24, 0.2) 99%);
  }

  &.red {
    background-image: linear-gradient(270deg, transparent 5%, rgba(127, 38, 36, 0.37) 99%);
  }
}