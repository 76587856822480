.club-stats-box {
  position: relative;
  margin-bottom: 30px;

  .club-stats-box__heading {
    margin: 0 0 10px;
    padding: 0 5px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: 900;
    color: $aug_gold;
  }

  .club-stats-box__content {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    gap: 20px;
  }

  .club-stats-box__members-count {
    @include aug_bg_dark();
    background-image: linear-gradient(340deg, rgba(0, 0, 0, 0.2) 5%, rgba(51, 118, 73, 0.37) 80%);

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 8px;

    &--count {
      font-size: 32px;
      text-align: center;
    }

    &--description {
      font-size: 14px;
      text-align: center;
      margin-bottom: 8px;
    }
  }

  .club-stats-box__members-subcounts {
    display: flex;
    flex-direction: column;

    .club-stats-box__members-subcount {
      @include aug_bg_dark();

      padding: 5px 10px 12px;
      margin-bottom: 10px;
      border-radius: 8px;

      &:last-child {
        margin: 0;
      }

      &.full {
        background-image: linear-gradient(340deg, rgba(0, 0, 0, 0.2) 5%, rgba(51, 74, 118, 0.37) 80%);
      }

      &.supporting {
        background-image: linear-gradient(340deg, rgba(0, 0, 0, 0.2) 5%, rgba(51, 118, 116, 0.37) 80%);
      }

      &.honourable {
        background-image: linear-gradient(340deg, rgba(0, 0, 0, 0.2) 5%, rgba(51, 95, 118, 0.37) 80%);
      }

      &.idle {
        background-image: linear-gradient(340deg, rgba(0, 0, 0, 0.2) 5%, rgba(53, 51, 118, 0.37) 80%);
      }

      &--description {
        font-size: 10px;
        line-height: 1;
        text-align: center;
      }

      &--count {
        font-size: 15px;
        font-weight: 500;
        text-align: center;
      }
    }
  }

  &.dummy {
    .loader-wrapper {
    background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }

    .club-stats-box__members-count {
      background-image: linear-gradient(340deg, rgba(0, 0, 0, 0.2) 5%, rgba(20, 20, 20, 0.37) 80%);
    }

    .club-stats-box__members-subcounts {
      width: 32%;

      .club-stats-box__members-subcount {
        height: 50px;
        background-image: linear-gradient(340deg, rgba(0, 0, 0, 0.2) 5%, rgba(20, 20, 20, 0.37) 80%);
      }
    }
  }
}