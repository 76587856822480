.training-stats {
  .season-stats {
    margin-bottom: 40px;

    .season-stats__trainings-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .season-stats__trainings-list--item {
      @include aug_bg_dark();

      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      padding: 5px 10px;
      border-bottom: 1px solid;
    }
  }
}