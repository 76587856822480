.myaug-popup__wrapper .myaug-popup.home-app-info-popup {
  padding: 0;

  .home-app-info-popup__header-bar {
    @include aug_bg_dark();

    padding: 5px 10px;
    font-size: 14px;
    margin-bottom: 10px;
    border-top: 1px solid $aug_gold;
    color: grey;
  }

  .home-app-info-popup__content {
    padding: 10px;
  }
}