.personal-data {
  .personal-data__data-list {
    @include aug_bg_dark();

    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 15px;
  }

  .personal-data__data-list-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid black;

    &:last-child {
      border: 0;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }

    .personal-data__data-list--label {
      width: 280px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 16px;
    }

    .personal-data__data-list--value {
      flex: 1;
      color: $aug_gold;
      font-size: 20px;
      margin-left: 15px;
    }

    .personal-data__data-list--edit-button {
      position: absolute;
      top: 10px;
      right: 0;
      width: 30px;
      height: 30px;
      background-image: url('../../img/icon-edit.svg');
      background-position: center;
      background-size: 70%;
      background-repeat: no-repeat;

      @include media-breakpoint-up(md) {
        position: relative;
        top: initial;
        right: initial;
      }
    }
  }

  ul {
    li {
      margin-bottom: 30px;

      &:last-of-type {
        margin:0;
      }
    }
  }

  textarea {
    width: 100%;
    padding: 10px;
    line-height: 1.8;
  }

  .personal-data__label-description {
    margin: 5px 0;
    font-size: 14px;
    color: grey;
    font-weight: 300;
    line-height: 1.28;
    font-style: italic;
  }
}